body{
    color: black;
}

.issue-view .label, 
.issues-grid .react-grid-HeaderCell, 
.pagination .pagination-limit,
.pagination .range.btn-secondary.disabled{
    color: rgba(0, 0, 0, 0.6);
}

.pagination .range.btn-secondary.disabled{
    opacity: 1;
}

a{
    color: #007cff;
}

.uikit_5f7e92be_components_menubar__MainMenuItem_638cd422 {
    color: #a0bbd5;
}

.tab-content{
    background-color: #fff;
}

.uikit_5f7e92be_components_menubar__Panel_7b35cb99{
    width: 275px;
}