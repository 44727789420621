.streetmap-nodefects{
    padding: 15px;
}

.streetmap-defects-conainer{
    overflow-x: auto;

    .widget-table-container{
        min-height: auto;
        width: auto;
        flex-wrap: wrap;
    }

    .streetmap-defects-file-link:not(:last-child){
        margin-bottom: 10px;
    }

    .streetmap-defects-row{
        cursor: pointer;
    
        &.streetmap-defects-row-selected .widget-table__row{
            background-color: #e7f1ff;            
        }
    }

    .widget-table__header-row{
        position: relative;
        top: 0px;
        transition: top 0.1s;
    }
}

.streetmap-defects-fields{
    margin: 15px 10px 0;

    .btn{
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .base-collapse-btn{
        padding: 0px 10px;
        width: 170px;
        font-size: inherit;
        border-bottom: none;
        margin-bottom: 15px;
        background-color: #fff;
    }
}