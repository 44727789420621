@media print { 
    .issue-content:last-child {
      page-break-after: auto;
    }

    .streetpart-map{
      height: 800px;
    }

    .leaflet-bar {
      display: none;
    }
}