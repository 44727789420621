.streetpart-map{
    height: calc(100vh - 155px);
    z-index: 1;
    position: sticky;
    top: 99px;

    .quick-view__wrapper &{
        height: calc(100vh - 213px);;
        position: relative;
        top: 0px;
    }

    .leaflet-touch .leaflet-bar a{
        text-decoration: none;
    }

    .marker-cont {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background: #00AB4E;
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);

        &.orange {
            background: #FF722B;
        }

        &.red {
            background: #FF494F;
        }

        &.selected-marker {
            border: 3px solid #FFF;            
        }

        &.red-outline {
            outline: 4px solid #F33542;            
        }

        .above-label {
            position: absolute;
            top: -16px;
            left: -21px;
            width: 70px;
            text-align: center;
            z-index: 2;
        }

        .below-label {
            position: absolute;
            top: 29px;
            left: -21px;
            line-height: 12px;
            width: 70px;
            text-align: center;
        }

        .icon {
            position: absolute;
        }

        .icon-lamp {
            width: 16px;
            height: 16px;
            left: 50%;
            margin-left: -8px;
            top: 50%;
            margin-top: -8px;
            mask: url(./svg/default.svg);
            mask-size: 16px;
            background-color: #fff;
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important;
        }

        .info-icons {
          z-index: 1;
          width: 16px;
          height: 16px;
          position: absolute;
          border-radius: 100%;
          background: #ffffff;
          top: -3px;
          right: -11px;

            &.info-icons-reconstruction {
                left: -11px;
                right: auto;
            }

            &.info-icons-repeated-defect {
                left: -10px;
                top: 16px;
                right: auto;
            }

            &.info-icons-elimination {
                top: 16px;
            }

            .icon-twenty-four {
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -6px;
                mask: url(./svg/violation_24h.svg);
                -webkit-mask-size: 12px;
                background-color: #F33542;
            }

            .icon-elimination {
                position: absolute;
                width: 15px;
                height: 15px;
                mask: url(./svg/elimination.svg);
                -webkit-mask-size: 16px;
                background-color: #3b72a8;
            }

            .icon-elimination-rejected {
                position: absolute;
                width: 11px;
                height: 11px;
                left: 2px;
                top: 2px;
                background-image: url(./svg/cross.svg);
            }

            .icon-repeated-defect {
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -6px;
                mask: url(./svg/repeat.svg);
                -webkit-mask-size: 12px;
                background-color: #3b72a8;
            }

            .icon-clock {
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -6px;
                mask: url(./svg/sand_clock.svg);
                -webkit-mask-size: 12px;
                background-color: #3b72a8;
            }

            .icon-chain {
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -6px;
                mask: url(./svg/chain.svg);
                -webkit-mask-size: 12px;
                background-color: #3b72a8;
            }
            
            .icon-reconstruction {
                position: absolute;
                width: 12px;
                height: 12px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -6px;
                mask: url(./svg/reconstruction.svg);
                -webkit-mask-size: 12px;
                background-color: #3b72a8;
            }
        }
    }      
}